import React from "react";
import { BoxPlotChart, AutoSizeTextArea, FieldInput } from "@aim-mf/styleguide";
import { stringToJson, dataStringFormat, JSONValidation } from "../utils";
import { chartData } from "../fakeData";

class BoxPlotChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // BarChartMax: 70,
      chartHeight: "20rem",
      chartWidth: "30rem",
      chartData: chartData.boxPlot,
      chartChartBackgroundColor: "rgba(0,0,0,0)",
    };
  }

  render() {
    return (
      <div>
        <BoxPlotchart
          // max={this.state.BarChartMax}
          height={this.state.chartHeight}
          width={this.state.chartWidth}
          data={stringToJson(this.state.chartData, chartData.boxPlot)}
          onBoxClick={() => {}}
          dataChange={(value) => {
            this.setState({ chartData: value });
          }}
          dataText={this.state.chartData}
          chartBackgroundColor={this.state.chartChartBackgroundColor}
          onBarChartHeightChange={(event) => {
            this.setState({ chartHeight: event.target.value });
          }}
          onBarChartWidthChange={(event) => {
            this.setState({ chartWidth: event.target.value });
          }}
          onChartBackgroundColorChange={(event) => {
            this.setState({ chartChartBackgroundColor: event.target.value });
          }}
        />
      </div>
    );
  }
}

const BoxPlotchart = (props) => {

    let backgroundColor=JSONValidation(props.dataText, chartData.boxPlot)
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        width: "90%",
        marginLeft: "5%",
        flexWrap: "wrap",
      }}
    >
      <span
        style={{
          whiteSpace: "pre-wrap",
          color: "white",
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        {
          "Bar Chart\n\t1. the 'max' attribute in the BarChart is optional and we usually do not provide such attribute. It define the highest data point in the chart, (e.g. if max is 70, all the data point above 70 will be at the max bar height)"
        }
      </span>
      <BoxPlotChart
        Xmin={props.data.upper}
        Ymin={props.data.lower}
        YAxisNumberFont={"0.5rem Roboto, sans-serif"}
        height={props.height}
        width={props.width}
        data={props.data}
        onBoxClick={props.onBoxClick}
        chartBackgroundColor={props.chartBackgroundColor}
      />
      <div style={{ whiteSpace: "pre-wrap", color: "white" }}>
        {"JSX\n\n<BoxPlotChart\n" +
          "\tmax={max}                     // int optional\n" +

       " \tXmin={data.upper}            \n\tYmin={data.lower}            \n\tYAxisNumberFont={'0.5rem Roboto, sans-serif'}\n"+
          '\theight={"'}
        <div style={{ display: "inline-flex", height: "30px" }}>
          <FieldInput
            fieldStyle={{ height: "30px", width: "100px" }}
            hint={props.height}
            onChange={props.onBarChartHeightChange}
          />
        </div>
        {'"}      // string \n' + '\twidth={"'}
        <div style={{ display: "inline-flex", height: "30px" }}>
          <FieldInput
            fieldStyle={{ height: "30px", width: "100px" }}
            hint={props.width}
            onChange={props.onBarChartWidthChange}
          />
        </div>
        {'"}      // string \n' +
          "\tdata={data}                          // JSON data\n" +
          "\tonBoxClick={onBoxClick}     // callback function\n" +
          '\tchartBackgroundColor={"'}
        <div style={{ display: "inline-flex", height: "30px" }}>
          <FieldInput
            fieldStyle={{ height: "30px", width: "100px" }}
            hint={props.chartBackgroundColor}
            onChange={props.onChartBackgroundColorChange}
          />
        </div>
        {'"}      // string \n' + "/>"}
      </div>
      <div>
        <AutoSizeTextArea
          fieldstyle={{
            whiteSpace: "pre-wrap",
            color: "white",
            backgroundColor: backgroundColor,
            width: "30rem",
          }}
          value={props.dataText}
          CustomizedOnChange={props.dataChange}
          title={"Data sample"}
        />
      </div>
    </div>
  );
};

export { BoxPlotChartComponent };
