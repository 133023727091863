import React from "react";
import { LineChart, AutoSizeTextArea, FieldInput } from "@aim-mf/styleguide";
import { stringToJson, dataStringFormat, JSONValidation } from "../utils";
import { chartData } from "../fakeData";

class LineChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // BarChartMax: 70,
      chartHeight: "20rem",
      chartWidth: "30rem",
      chartData: dataStringFormat(chartData.lineChart),
      chartChartBackgroundColor: "rgba(0,0,0,0)",
    };
  }

  render() {
    return (
      <div>
        <Linechart
          // max={this.state.BarChartMax}
          height={this.state.chartHeight}
          width={this.state.chartWidth}
          data={stringToJson(this.state.chartData, chartData.lineChart)}
          onBarClick={() => {}}
          dataChange={(value) => {
            this.setState({ chartData: value });
          }}
          dataText={this.state.chartData}
          chartBackgroundColor={this.state.chartChartBackgroundColor}
          onBarChartHeightChange={(event) => {
            this.setState({ chartHeight: event.target.value });
          }}
          onBarChartWidthChange={(event) => {
            this.setState({ chartWidth: event.target.value });
          }}
          onChartBackgroundColorChange={(event) => {
            this.setState({ chartChartBackgroundColor: event.target.value });
          }}
        />
      </div>
    );
  }
}

const Linechart = (props) => {

    let backgroundColor=JSONValidation(props.dataText, chartData.lineChart)
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        width: "90%",
        marginLeft: "5%",
        flexWrap: "wrap",
      }}
    >
      <span
        style={{
          whiteSpace: "pre-wrap",
          color: "white",
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        {
          "Bar Chart\n\t1. the 'max' attribute in the BarChart is optional and we usually do not provide such attribute. It define the highest data point in the chart, (e.g. if max is 70, all the data point above 70 will be at the max bar height)"
        }
      </span>
      <LineChart
        // max={props.max}
        height={props.height}
        width={props.width}
        data={props.data}
        onBarClick={props.onBarClick}
        chartBackgroundColor={props.chartBackgroundColor}
      />
      <div style={{ whiteSpace: "pre-wrap", color: "white" }}>
        {"JSX\n\n<LineChart\n" +
          "\tmax={max}                     // int optional\n" +
          '\theight={"'}
        <div style={{ display: "inline-flex", height: "30px" }}>
          <FieldInput
            fieldStyle={{ height: "30px", width: "100px" }}
            hint={props.height}
            onChange={props.onBarChartHeightChange}
          />
        </div>
        {'"}      // string \n' + '\twidth={"'}
        <div style={{ display: "inline-flex", height: "30px" }}>
          <FieldInput
            fieldStyle={{ height: "30px", width: "100px" }}
            hint={props.width}
            onChange={props.onBarChartWidthChange}
          />
        </div>
        {'"}      // string \n' +
          "\tdata={data}                          // JSON data\n" +
          "\tonBarClick={onBarClick}     // callback function\n" +
          '\tchartBackgroundColor={"'}
        <div style={{ display: "inline-flex", height: "30px" }}>
          <FieldInput
            fieldStyle={{ height: "30px", width: "100px" }}
            hint={props.chartBackgroundColor}
            onChange={props.onChartBackgroundColorChange}
          />
        </div>
        {'"}      // string \n' + "/>"}
      </div>
      <div>
        <AutoSizeTextArea
          fieldstyle={{
            whiteSpace: "pre-wrap",
            color: "white",
            backgroundColor: backgroundColor,
            width: "30rem",
          }}
          value={props.dataText}
          CustomizedOnChange={props.dataChange}
          title={"Data sample"}
        />
      </div>
    </div>
  );
};

export { LineChartComponent };
