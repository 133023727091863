const chartData = {
    barChart : '[{"label":"category name 1","data":10,"color":"red"},{"label":"category name 2","data":20,"color":"blue"}]',
    stackBarChart:'[[{"label":"category name 1","data":10,"color":"blue"},{"label":"category name 2","data":20,"color":"blue"}],[{"label":"category name 1","data":10,"color":"red"},{"label":"category name 2","data":20,"color":"red"}]]',
    lineChart: '[{"label":"2019","data":10,"color":"red"},{"label":"2020","data":20,"color":"blue"},{"label":"2021","data":15,"color":"blue"}]',
    bubbleChart: '[{"x": 2500,"y": 50000,"size": 500000,"category": "Microsoft"}, {"x": 500,"y": 110000,"size": 7600000,"category": "Starbucks"}, {"x": 7000,"y": 19000,"size": 700000,"category": "Google"}]',
    scatterChart: '[{"x": 2500,"y": 50000,"color": "blue","borderColor": "red","name": "Microsoft", "tooltipData":2000}, {"x": 500,"y": 110000,"color": "red","name": "Starbucks", "tooltipData":3000}, {"x": 7000,"y": 19000,"size": 700000,"name": "Google", "tooltipData":"15000"}]',
    boxPlot: '[\n\t{\n\t\t"lower":10,\n\t\t"q1":13,\n\t\t"median":15,\n\t\t"q3":18,\n\t\t"upper":22,\n\t\t"outliers":[28], \n\t\t"category":"label"\n\t},{\n\t\t"lower":5,\n\t\t"q1":12,\n\t\t"median":18,\n\t\t"q3":28,\n\t\t"upper":42,\n\t\t"outliers":[50,60,2], \n\t\t"category":"year"\n\t}\n]',
    horizontalBarChart: '[{"label":"category name 1","data":10,"color":"red"},{"label":"category name 2","data":20,"color":"blue"}]',
}

export { chartData }