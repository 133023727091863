import React from "react";
import { BarChartComponent } from "./chartDemoComponent/barChart";
import { StackBarChartComponent } from "./chartDemoComponent/stackBarChart";
import { LineChartComponent } from "./chartDemoComponent/lineChart";
import {BubbleChartComponent} from "./chartDemoComponent/bubbleChart";
import {ScatterChartComponent} from "./chartDemoComponent/scatterChart";
import {HorizontalBarChartComponent} from "./chartDemoComponent/horizontalBarChart";
import {BoxPlotChartComponent} from "./chartDemoComponent/boxPlotChart";

class ChartDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <BarChartComponent />
        <StackBarChartComponent />
        <LineChartComponent />
        <BubbleChartComponent/>
        <ScatterChartComponent/>
        <HorizontalBarChartComponent/>
        <BoxPlotChartComponent/>
      </div>
    );
  }
}

export { ChartDemo };
