import React from "react";
import {
  mapping,
  SmallAvatar,
  ButtonSolid,
  userProfileStyle,
} from "@aim-mf/styleguide";

class TableDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
        <div>
        </div>
    );
  }
}

export { TableDemo };
