import { chartData } from "./fakeData";

const stringToJson = (data, defaultData) => {
  let returnData;
  try {
    returnData = JSON.parse(data);
  } catch (error) {
    returnData = JSON.parse(defaultData);
  }
  return returnData;
};

const dataStringFormat = (data) => {
  return data
    .replaceAll("[", "[\n\t")
    .replaceAll("]", "\n]")
    .replaceAll("{", "{\n\t\t")
    .replaceAll("}", "\n\t}")
    .replaceAll(",", ",\n\t\t")
    .replaceAll("},\n\t\t", "},\n\t")
    .replaceAll("\t\t[", "[")
    .replaceAll("\t[", "[");
};

const JSONValidation = (JSONText, defaultData) => {
  let JSONString = "default";
  let backgroundColor = "rgba(0,0,0,0.1)";
  if (JSONText === dataStringFormat(defaultData) || JSONText === defaultData) {
    JSONString = "default";
    backgroundColor = "rgba(0,0,0,0.1)";
  } else {
    try {
      JSON.parse(JSONText);
      JSONString = "valid";
      backgroundColor = "rgba(0,255,0,0.1)";
    } catch (e) {
      JSONString = "invalid";
      backgroundColor = "rgba(255,0,0,0.1)";
    }
  }
  return backgroundColor;
};

export { stringToJson, dataStringFormat, JSONValidation };
