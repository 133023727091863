import React from "react";
import { TableDemo } from "./page/tableDemo";
import { ChartDemo } from "./page/chartDemo";
import { ButtonDemo } from "./page/buttonDemo";

function App() {
  return (
    <div style={{color:"white"}}>
        Chart demo interactive component
      <ChartDemo />
      <ButtonDemo />
      <TableDemo />
    </div>
  );
}

export default App;
