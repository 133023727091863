import React from "react";
import { StackBarChart, AutoSizeTextArea, FieldInput } from "@aim-mf/styleguide";
import {stringToJson, dataStringFormat, JSONValidation} from "../utils";
import { chartData } from "../fakeData";

class StackBarChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartHeight: "20rem",
            chartWidth: "30rem",
            chartData: dataStringFormat(chartData.stackBarChart),
            chartChartBackgroundColor: "rgba(0,0,0,0)",
            chartStack:"true"
        };
    }

    render() {
        return (
            <div>
                <StackBarchart
                    // max={this.state.BarChartMax}
                    height={this.state.chartHeight}
                    width={this.state.chartWidth}
                    data={stringToJson(this.state.chartData, chartData.stackBarChart)}
                    onBarClick={() => {}}
                    dataChange={(value) => {
                        this.setState({ chartData: value });
                    }}
                    dataText={this.state.chartData}
                    stack={this.state.chartStack}
                    chartBackgroundColor={this.state.chartChartBackgroundColor}
                    onBarChartHeightChange={(event) => {
                        this.setState({ chartHeight: event.target.value });
                    }}
                    onBarChartWidthChange={(event) => {
                        this.setState({ chartWidth: event.target.value });
                    }}
                    onChartBackgroundColorChange={(event) => {
                        this.setState({ chartChartBackgroundColor: event.target.value });
                    }}

                    onStackChange={(event) => {
                        this.setState({ chartStack: event.target.value });
                    }}
                />
            </div>
        );
    }
}

const StackBarchart = (props) => {
    let backgroundColor=JSONValidation(props.dataText, chartData.stackBarChart)
    return (
        <div
            style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "90%",
                marginLeft: "5%",
                flexWrap: "wrap",
            }}
        >
      <span style={{ whiteSpace:"pre-wrap", color: "white", width: "100%", marginBottom:"2rem" }}>
        {
            "Bar Chart\n" +
            "\t1. the 'max' attribute in the BarChart is optional and we usually do not provide such attribute. It define the highest data point in the chart, (e.g. if max is 70, all the data point above 70 will be at the max bar height)\n" +
            '\t2. the "stack" attribute in the StackBarChart can only be choose from [true, false, {"type":"100%"}]'
        }
      </span>
            <StackBarChart
                // max={props.max}
                height={props.height}
                width={props.width}
                data={props.data}
                onBarClick={props.onBarClick}
                chartBackgroundColor={props.chartBackgroundColor}
                stack={props.stack === "true" ? true : (props.stack === '{"type":"100%"}' ? JSON.parse(props.stack) : (props.stack === 'false' ? false : props.stack))}
            />
            <div style={{ whiteSpace: "pre-wrap", color: "white" }}>
                {"JSX\n\n<StackBarChart\n" +
                "\tmax={max}                     // int optional\n" +
                '\theight={"'}
                <div style={{ display: "inline-flex", height: "30px" }}>
                    <FieldInput
                        fieldStyle={{ height: "30px", width: "100px" }}
                        hint={props.height}
                        onChange={props.onBarChartHeightChange}
                    />
                </div>
                {'"}      // string \n' + '\twidth={"'}
                <div style={{ display: "inline-flex", height: "30px" }}>
                    <FieldInput
                        fieldStyle={{ height: "30px", width: "100px" }}
                        hint={props.width}
                        onChange={props.onBarChartWidthChange}
                    />
                </div>
                {'"}      // string \n' +
                "\tdata={data}                          // JSON data\n" +
                "\tonBarClick={onBarClick}     // callback function\n" +
                '\tchartBackgroundColor={"'}
                <div style={{ display: "inline-flex", height: "30px" }}>
                    <FieldInput
                        fieldStyle={{ height: "30px", width: "100px" }}
                        hint={props.chartBackgroundColor}
                        onChange={props.onChartBackgroundColorChange}
                    />
                </div>
                {'"}      // string \n' +
                '\tstack={'}
                <div style={{ display: "inline-flex", height: "30px" }}>
                    <FieldInput
                        fieldStyle={{ height: "30px", width: "100px" }}
                        hint={props.stack}
                        onChange={props.onStackChange}
                    />
                </div>
                {'}      // true or false or {"type":"100%"} \n' +

                "/>"}
            </div>
            <AutoSizeTextArea
                fieldstyle={{
                    whiteSpace: "pre-wrap",
                    color: "white",
                    backgroundColor: backgroundColor,
                    width: "30rem",
                    height: "30rem",
                }}
                value={props.dataText}
                CustomizedOnChange={props.dataChange}
                title={"Data sample"}

            />
        </div>
    );
};


export { StackBarChartComponent };
